import moment from "moment"
import { connect } from "react-redux";
import React, { Component } from 'react'
import { Link, NavLink as NavLinkRoute, useParams } from "react-router-dom"
import {
  Badge, Button, Col,
  FormGroup,
  //FormGroup,
  Input, InputGroup, InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  //Label,
  Nav,
  NavItem,
  NavLink,
  Pagination, PaginationItem, PaginationLink, Row,
  TabContent,
  TabPane
} from "reactstrap"
import LoginForm from "./LoginForm"
import PostModal from "./PostModal"
import { Helmet } from 'react-helmet'
import LoginModal from "./LoginModal"
import authActions from "../../redux/auth/actions";
import communityActions from "../../redux/community/actions";
import frontActions from "../../redux/front/actions";
import Select from 'react-select'
import Loading from "../../shared/components/Loading";
import { protectSpecialUsername, usernameToColor } from '../../helpers/utils';

const { userLogout } = authActions;
const {
  communityGetCategories,
  communityGetAllQuestions,
  communityQuestionPin,
  communityQuestionMoveTopic,
} = communityActions;
const { fetchHelpArticles } = frontActions;

const CategoryLinkItem = ({ id, topic_slug, name, params, category_slug }) => {
  return (
    <li className="topics-nav-item" key={`cat-nav-topic-id-${id}`}>
      <NavLinkRoute
        to={`/community/questions/${category_slug}/${topic_slug}`}
        //active={params.slug === topic_slug ? true : false}
        className={({ isActive }) => (params.slug === topic_slug ? 'active' : 'inactive')}
      >
        <span className="icon icon-category" />
        <span className="topics-nav-title">{name}</span>
      </NavLinkRoute>
    </li>
  )
}

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class CommunityQuestions extends Component {
  state = {
    activeTab: '',
    isLoggedIn: false,
    showLoginModal: false,
    search: "",
    page: 1,
    moveQuestionId: null
  }

  componentDidMount = () => {
    this.props.communityGetCategories();
    this.props.communityGetAllQuestions({
      category: this.props.params?.category,
      topic: this.props.params?.slug,
      search: this.state.search,
    });
    this.props.fetchHelpArticles();
  }

  componentDidUpdate = (pp) => {
    if(pp.params?.category !== this.props.params?.category
      || pp.params?.slug !== this.props.params?.slug){
      this.props.communityGetAllQuestions({
        category: this.props.params?.category,
        topic: this.props.params?.slug,
        search: this.state.search,
      });
    }
  }

  askQuestion = () => {
    this.setState({ showPostModal: true })
  }

  displayCategories = () => {
    const { categories, params } = this.props;

    return (
      <React.Fragment>
        {Object.keys(categories).map(cat_key => {
          return (
            <div className="category-nav" key={`cat-nav-id-${cat_key}`}>
              <span className="category-nav-title">{categories[cat_key]?.name}</span>
              <ul className="topics-nav">
                {Object.keys(categories[cat_key]?.topics).map(top_key => {
                  // let's pretend that this is our slug
                  // since we control the topics (at admin area), we can manually create slug for each topic
                  // so in this case, there will be a slug input for topic when admin add/edit it
                  return (
                    <CategoryLinkItem
                      id={top_key}
                      category_slug={categories[cat_key].slug}
                      topic_slug={categories[cat_key]?.topics[top_key]?.slug}
                      name={categories[cat_key]?.topics[top_key]?.name}
                      params={params}
                      key={`topic-nav-${top_key}`}
                    />
                  )
                })}
              </ul>
            </div>
          )
        })}
      </React.Fragment>
    )
  }

  findCategoryBySlug = (slug) => {
    const { categories } = this.props;
    const cats = Object.keys(categories).map(key => { return categories[key]; })
    if(!cats){ return null }
    return cats.find(item => item.slug === slug);
  }

  findTopicBySlug = (slug) => {
    const { categories } = this.props;
    const keys = Object.keys(categories).map(key => { return key; });
    var topics = [];
    keys.forEach(key => {
      const t = Object.keys(categories[key]?.topics).map(key_topic => { return categories[key]?.topics[key_topic]; })
      t.forEach(topic => topics.push(topic));
    })
    if(!topics){ return null }
    return topics.find(item => item.slug === slug);
  }

  createMoveOptions = (id) => {
    const { categories } = this.props;
    if(!categories){ return []; }
    var options = [];
    Object.keys(categories).forEach(key => {
      Object.keys(categories[key].topics).forEach(topic_key => {
        options.push({
          label: categories[key].topics[topic_key].name,
          value: topic_key,
          id_category: key,
        })
      })
    })
    return options.filter(item => item.value !== id?.toString())
  }

  render() {
    const { activeTab, showPostModal, showLoginModal, search, moveQuestionId } = this.state
    const {
      userData,
      categories,
      questions,
      params,
      questions_working,
      help_articles,
    } = this.props;

    //console.log("Categories:", categories);
    //console.log("Questions:", questions);
    //console.log("Help Articles:", help_articles);

    return (
      <Row>
        <Helmet>
          <title>{
            params?.slug
              ? this.findTopicBySlug(params?.slug)?.name || ""
              : "Community"
            } | AccelerList</title>
          <meta
            name="description"
            content={params?.slug
                ? `${this.findTopicBySlug(params?.slug)?.name} questions`
                : "Ask question and get answer at AccelerList Community"
            }
          />
        </Helmet>
        <Col md={9} className="pe-4">
          <div className="inner-page-header">
            <div className="hstack justify-content-between">
              {params?.slug &&
                <Link
                  to="/community/questions"
                  onClick={() => this.props.communityGetAllQuestions()}
                >&larr; Back to questions</Link>
              }
            </div>
            <div className="hstack justify-content-between mt-3">
              <h1 className="inner-page-title">{
                params?.slug
                  ? this.findTopicBySlug(params?.slug)?.name
                  : "Community"
                } Questions
              </h1>
              <div className="hstack gap-3">
                {/*
                {params?.slug &&
                  <div>
                    <Select
                      options={sortOptions}
                      value={this.state.activeSort}
                      onChange={ selected => this.setState({ activeSort: selected }) }
                    />
                  </div>
                }
                */}
                <Button color="primary" onClick={this.askQuestion}>Ask a question</Button>
              </div>
            </div>
            <PostModal
              isOpen={showPostModal}
              isLoggedIn={userData?.username ? true : false}
              toggle={() => this.setState({ showPostModal: !showPostModal})}
              onLogin={() => this.setState({ isLoggedIn: true })}
              categories={categories}
            />
            <LoginModal
              isOpen={showLoginModal}
              toggle={() => this.setState({ showLoginModal: !showLoginModal})}
            />
          </div>
          <div className="inner-page-content">
            <InputGroup className="input-group-search">
              <InputGroupText>
                <span className="icon icon-search" />
              </InputGroupText>
              <Input
                placeholder="Search..."
                value={search}
                onChange={(e) => this.setState({search: e.target.value})}
              />
              {search &&
              <Button
                className="btn-clear"
                role="button"
                onClick={() => {
                  this.props.communityGetAllQuestions({
                    category: this.props.params?.category,
                    topic: this.props.params?.slug,
                  })
                  this.setState({search: ""});
                }}
              >
                &#x2715;
              </Button>
              }
              <Button
                color="light"
                disabled={!search || questions_working}
                onClick={() => this.props.communityGetAllQuestions({
                  category: this.props.params?.category,
                  topic: this.props.params?.slug,
                  search: this.state.search,
                })}
              >
                Go
              </Button>
            </InputGroup>
            {questions_working ?
            <Loading className="py-4" />
            :
            <React.Fragment>
            <ul className="questions-list">
              <li className="questions-list-header">
                <div className="questions-list-header-col"></div>
                <div className="questions-list-header-col">Question/Topic</div>
                <div className="questions-list-header-col">Replies</div>
                <div className="questions-list-header-col">Last active</div>
                {userData?.is_admin &&
                <div className="questions-list-header-col"></div>
                }
              </li>
              {questions?.items?.map(item => (
              <li
                className="questions-list-item"
                title={item.title}
                key={`question-${item.id}`}
              >
                <Link to={`/community/questions/${item.slug}`}>
                  <div className="questions-list-item-col text-center">
                    <div className="d-flex align-items-center flex-column lh-1">
                      <span className="icon icon-18 icon-caret-up icon-vote" />
                        <span className="post-vote-count">{item?.upvotes ? item?.upvotes : 0}</span>
                    </div>
                  </div>
                  <div className="questions-list-item-col">
                    <h2 className="question-title text-truncate">
                      {item.is_duplicate && <span className="question-badge question-duplicate me-1">DUPLICATE</span>}
                      {item.resolved && <span className="question-badge question-resolved me-1">RESOLVED</span>}
                      {item.title}
                    </h2>
                    <span className="question-category">{item.category?.name} &gt; <span className="question-topic">{item?.topic?.name}</span></span>
                  </div>
                  <div className="questions-list-item-col">{item.replies_count ? item.replies_count : 0}</div>
                  <div className="questions-list-item-col">
                    {moment(item.updated_at).from(new Date())}<br/>by {protectSpecialUsername(item?.author?.username)}
                  </div>
                  <div className="questions-list-item-col text-end">
                    {/* Admin: move question to another topic */}
                    {userData.is_admin &&
                    <React.Fragment>
                      <span
                        title="Move question"
                        role="button"
                        className="me-2"
                        //disabled={}
                        onClick={(e) => {
                          e.preventDefault()
                          this.setState({ moveQuestionId: item.id })
                        }}
                      >&#x21aa;</span>
                    </React.Fragment>
                    }
                    {/* Admin: can view pin and do action, non-admin: only see pinned indicator */}
                    {userData?.is_admin ?
                    <span
                      className={`icon icon-18 icon-pin ${item.pinned ? "bg-danger" : ""}`}
                      title="Pin question"
                      role="button"
                      onClick={(e) => {
                        e.preventDefault()
                        this.props.communityQuestionPin({
                          id_question: item.id})
                        }
                      }
                    />
                    : item.pinned ? <span className="icon icon-18 icon-pin bg-danger"></span> : null
                    }
                  </div>
                </Link>
              </li>
              ))}
            </ul>
            <Pagination size="sm" listClassName="justify-content-center">
              <PaginationItem disabled={questions.current_page <= 1 ? true : false}>
                <PaginationLink
                  onClick={() =>
                    this.props.communityGetAllQuestions({
                      category: this.props.params?.category,
                      topic: this.props.params?.slug,
                      search: this.state.search,
                      page: questions.current_page - 1,
                    })
                  }
                >Newer</PaginationLink>
              </PaginationItem>
              <PaginationItem disabled={questions.current_page >= questions.pages ? true : false}>
                <PaginationLink
                  onClick={() =>
                    this.props.communityGetAllQuestions({
                      category: this.props.params?.category,
                      topic: this.props.params?.slug,
                      search: this.state.search,
                      page: questions.current_page + 1,
                    })
                  }
                  disabled={questions.pages < questions.current_page}
                >Older</PaginationLink>
              </PaginationItem>
            </Pagination>
            <Modal
              isOpen={moveQuestionId ? true : false}
              toggle={() => this.setState({ moveQuestionId: null })}
              centered
            >
              <ModalHeader toggle={() => this.setState({ moveQuestionId: null })}>Move question</ModalHeader>
              <ModalBody>
                <FormGroup>
                  <Label>Move this question:</Label>
                  <strong className="text-info d-block">{questions?.items?.find(q => q.id === moveQuestionId)?.title}</strong>
                </FormGroup>
                <FormGroup>
                  <Label>To topic:</Label>
                  <Select
                    options={this.createMoveOptions(questions?.items?.find(q => q.id === moveQuestionId)?.id_topic)}
                    onChange={selected => {
                      // move question to selected topic
                      this.props.communityQuestionMoveTopic({
                        id_question: moveQuestionId,
                        id_category: selected.id_category,
                        id_topic: selected.value,
                      });
                      this.setState({ moveQuestionId: null })
                    }}
                  />
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button onClick={() => this.setState({ moveQuestionId: null })}>Cancel</Button>
                <Button color="primary">Move question</Button>
              </ModalFooter>
            </Modal>
            </React.Fragment>
            }
          </div>
        </Col>

        <Col md={3} className="community-col-left-separator">
          <div className="community-sidebar-item">
            <div className="community-sidebar-header">
              <h3 className="community-sidebar-title">My Profile</h3>
              {userData?.username &&
              <Button
                color="link"
                onClick={() => this.props.userLogout()}
              >Logout</Button>
              }
            </div>
            {userData?.username ?
            <React.Fragment>
              <Link to={`/community/profile`} className="community-profile-info">
                <div
                  className="community-profile-info-avatar"
                  style={userData?.profile_image && { backgroundImage: `url(${userData?.profile_image})`}}
                >
                  {userData?.profile_image ?
                  <img src={userData?.profile_image} alt={userData?.username} />
                  :
                  <span
                    className={`avatar-default`}
                    style={usernameToColor(userData?.username)}
                    title={userData?.username}
                  >{userData?.username.charAt(0)}</span>
                  }
                </div>
                <div>
                  <h6 className="community-profile-info-name">{userData?.username}</h6>
                  <span className="text-muted text-truncate d-block" style={{ maxWidth: '11rem' }}>{userData?.email}</span>
                </div>
              </Link>
              {/*<Button color="secondary" block className="mt-2">Edit profile</Button>*/}
            </React.Fragment>
            :
            <React.Fragment>
              <div>
                <p>Ask questions and get answers, please login or create account.</p>
                <p><Badge color="info">AccelerList user?</Badge> Login with your AccelerList credentials.</p>
              </div>
              {activeTab === '' ?
              <div className="d-flex flex-column gap-2">
                <Button color="primary" onClick={() => this.setState({ activeTab: 'login' })}>Login to community</Button>
                {/*<Button color="secondary" onClick={() => this.setState({ activeTab: 'signup' })}>Create account</Button>*/}
              </div>
              :
              <div>
                <Nav pills>
                  <NavItem>
                    <NavLink
                      //active={activeTab === 'login' ? true : false}
                      className={({ isActive }) => (activeTab === 'login' ? 'active' : 'inactive')}
                      onClick={() => this.setState({ activeTab: 'login' })}
                    >
                      Community Login
                    </NavLink>
                  </NavItem>
                  {/*
                  <NavItem>
                    <NavLink active={activeTab === 'signup'} onClick={() => this.setState({ activeTab: 'signup' })}>
                      Signup
                    </NavLink>
                  </NavItem>
                  */}
                </Nav>
                <TabContent activeTab={activeTab} className="pt-3">
                  <TabPane tabId="login">
                    <LoginForm />
                  </TabPane>
                  {/*
                  <TabPane tabId="signup">
                    <FormGroup>
                      <Label>First Name</Label>
                      <Input placeholder="Your first name" />
                    </FormGroup>
                    <FormGroup>
                      <Label>Last Name</Label>
                      <Input placeholder="Your last name" />
                    </FormGroup>
                    <FormGroup>
                      <Label>Email address</Label>
                      <Input type="email" placeholder="Your email address" />
                    </FormGroup>
                    <FormGroup>
                      <Label>Password</Label>
                      <Input placeholder="Password" />
                    </FormGroup>
                    <Button color="primary" block>Sign up</Button>
                  </TabPane>
                  */}
                </TabContent>
              </div>
              }
            </React.Fragment>
            }
          </div>
          <div className="community-sidebar-item">
            <div className="community-sidebar-header">
              <h3 className="community-sidebar-title">Topics</h3>
            </div>
            {this.displayCategories()}
          </div>
          <div className="community-sidebar-item">
            <div className="community-sidebar-header">
              <h3 className="community-sidebar-title">Popular Help Articles</h3>
            </div>
            <ul className="list-unstyled">
              {help_articles.map(item => (
                <li key={`article-${item.id}`} className="mb-3">
                  <a
                    href={`https://accelerlist.helpjuice.com/${item.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >{item.name}</a>
                </li>
              ))}
            </ul>
          </div>
        </Col>
      </Row>
    )
  }
}

export default withParams(connect(
  state => ({
    userData: state.User.get("userData"),
    login_working: state.Auth.get("login_working"),
    categories: state.Community.get("categories"),
    categories_working: state.Community.get("categories_working"),
    questions: state.Community.get("questions"),
    questions_working: state.Community.get("questions_working"),
    help_articles: state.Front.get("help_articles"),
  }),
  {
    userLogout,
    communityGetCategories,
    communityGetAllQuestions,
    communityQuestionPin,
    fetchHelpArticles,
    communityQuestionMoveTopic,
  }
)(CommunityQuestions));
