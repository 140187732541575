import React, { Component } from 'react';
import { Container } from "reactstrap";
import { ebayDashboard } from "../../../assets/images";

class Ebay extends Component {
  render() {
    return (
      <section className="ebay mb-0">
        <Container fluid className="text-white py-5" style={{ backgroundColor: '#33343a' }}>
          <Container>
            <div className="text-center">
              <h2 className="mb-3 fs-1 text-white fw-medium">Amazon sellers who cross list products to eBay make<br className="d-sm-none"/> 30% more sales each month.</h2>
              <p className="fs-5 mx-auto" style={{ maxWidth: '60rem' }}>
                Amazon is doubling down on their&nbsp;
                <a href="https://supplychain.amazon.com/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline' }} className="link-underline-info link-underline-opacity-0-hover text-white">Multi-Channel Fulfillment</a> program 
                and strategy because they want and need to thin their warehouse inventory. Your inventory, sitting in their warehouses, collecting dust 
                does them no good and doesn’t make money.
              </p>
            </div>
            <div style={{ maxWidth: '60rem' }} className="mx-auto">
              <img src={ebayDashboard} className="img-fluid" alt="eBay Dashboard"/>
            </div>
            <div className="text-center">
              <p className="fs-4 mx-auto" style={{ maxWidth: '70rem' }}>
                AccelerList makes it easy to cross list Amazon inventory to your eBay account. Make the sale and we track everything for you. 
                And it’s <span className="highlight-yellow px-3 fw-bold">FREE</span> with every subscription.
              </p>
              <a href="https://app.accelerlist.com/register" className="btn btn-primary btn-lg btn-icon hero-primary-btn">
                <span>Start listing now on eBay for FREE</span>
                <span className="icon icon-arrow-right icon-24"></span>
              </a>
            </div>
          </Container>
        </Container>
      </section>
    )
  }
}

export default Ebay;