import React, { Component } from "react";
import { speedArrows } from "../../../assets/images";
import './style.css';
import { Container } from "reactstrap";

class Pricing extends Component {
  componentDidMount = () => {
    if(window.location?.href?.indexOf("#pricing") > -1) {
      setTimeout(() => {
        this.targetRef.scrollIntoView({
          behavior: 'smooth'
        })
      }, 500)
    }
  }

  randomNumber = () => {
    const date = new Date();
    const generator = new Math.seedrandom(date.toDateString());
    const randomNumber = generator() * 20 + 12;
    return Math.floor(randomNumber);
  }

  render() {
    return (
      <section className="pricing" id="pricing" ref={ref => {this.targetRef = ref}}>
        <Container>
          <div className="section-heading mb-5">
            <h2 className="section-title">Start product listing today and make money on Amazon</h2>
          </div>
          <div className="pricing-plan">
            <div className="pricing-plan-box">
              <div className="pricing-plan-price">
                <div>
                  <span className="number">$39.98</span><span className="period">/month</span>
                </div>
              </div>
              <div className="pricing-plan-details">
                <BenefitList />
              </div>
              <div className="pricing-plan-footer">
                <a href="https://app.accelerlist.com/register/monthly" className="btn btn-info">Start your FREE 14 day trial</a>
              </div>
            </div>
            <div className="pricing-plan-box recommended">
              <div className="pricing-plan-price">
                <div>
                  <div>
                    <span className="old-price">$479.76</span>
                    <span className="notes ms-1">(5 months FREE)</span>
                  </div>
                  <span className="number">$280.00</span><span className="period">/year</span>
                </div>
                <div className="recommended-badge">
                  <div className="recommended-text"><span className="emoji">🔥</span> Most Recommended!</div>
                </div>
              </div>
              <div className="pricing-plan-details">
                <BenefitList />
              </div>
              <div className="pricing-plan-footer">
                <a href="https://app.accelerlist.com/register/annually" className="btn btn-info">Start your FREE 14 day trial</a>
              </div>
            </div>
            <div className="pricing-plan-box">
              <div className="pricing-plan-price">
                <div>
                  <span className="number">$499.00</span><span className="period">(lifetime)</span>
                </div>
              </div>
              <div className="pricing-plan-details">
                <BenefitList />
              </div>
              <div className="pricing-plan-footer">
                <a href="https://app.accelerlist.com/register/lifetime" className="btn btn-info">Start your FREE 14 day trial</a>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center mt-4">
            <div className="hero-cta-fomo">
              <img src={speedArrows} alt="" />
              <p>
                <strong>Hurry:</strong> {this.randomNumber()} sellers signed up <strong className="text-warning">today</strong>.
                Act now and <br/>start making $$$ on Amazon and eBay!
              </p>
            </div>
          </div>
        </Container>
      </section>
    )
  }
}

const BenefitList = () => (
  <div>
    <div className="text-success mb-3">
      <strong>FREE MOBILE APP & FREE EBAY LISTING</strong>
    </div>
    <ul className="benefit-list">
      <li><span>Built in box content solution</span></li>
      <li><span>Track Listers &amp; Sources</span></li>
      <li><span>Create custom MSKUs</span></li>
      <li><span>FBA &amp; Merchant Fulfilled Product Listing</span></li>
      <li><span>Effortless Product Listing</span></li>
      <li><span>Product Research</span></li>
      <li><span>Free employee accounts</span></li>
      <li><span>Free mobile scouting app</span></li>
      <li><span>Free eBay cross platform product listing</span></li>
    </ul>
  </div>
)

export default Pricing;
